import { Link } from 'react-router-dom'
import "./Footer.css"
import InfoLegali from "./InfoLegali"
import logo from "../img/loghi/logo-sporting.png"
import sponsor1 from "../img/sponsor1.jpg"
import sponsor2 from "../img/sponsor2.jpg"
import logoDenina from "../img/loghi/logoscuola.png"

const Footer = () => {
    return (
        <div id="footer">
            <div id="up">
                <div className='logo'>
                    <Link to="/"><img src={logo} id="logo-sporting" alt="logo"/></Link>
                    <span id='version'>v100524</span>
                    <Link to="/su-di-noi" className='logo-den-link'><img src={logoDenina} className="logodenina" alt="logo"/></Link>
                </div>
                <div>
                    <div className='item'>
                        <h3>Contatti</h3>
                        <span>Tel: 333 436 3911</span>
                        <span>Email: marcotorello1979@libero.it</span>
                    </div>
                    <div className='item'>
                        <h3>Dove ci trovi</h3>
                        <span>Via S. Bernardino, 28</span>
                        <span>Città: Saluzzo</span>
                    </div>
                </div>
            </div>
            <div className='sponsor'>
                <h3>I nostri sponsor:</h3>
                <img src={sponsor1} alt="logo"/>
                <img src={sponsor2} alt="logo"/>
            </div>
            <div>
            <h3>Info legali:</h3>
            <InfoLegali/>
            <br></br>
            <Link to="/privacy">Privacy Policy</Link>
            </div>
        </div>
    )
}

export default Footer